import React from 'react'
import PropTypes from 'prop-types'


import gabi from '../images/gabi1.jpg'
import celina from '../images/celina1.jpg'
import koch from '../images/koch1.jpg'

import anfahrtNeu from '../images/neuAnfahrt.jpg'

import oeffnungNeu from '../images/neuOeffnung.jpg'

import neuigkeitenNeu from '../images/neuNews.jpg'

import leistungNeu from '../images/neuLeistung.jpg'





import { Link, graphql, StaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'



class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="zeiten" className={`${this.props.article === 'zeiten' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Öffnungszeiten</h2>
          <span className="image_wthtbckgrnd main"><img src={oeffnungNeu} alt="" /></span>
          <p className="textePro">
          <ul>
            <li><span className="icon fa-clock-o"></span> &ensp;Unsere Öffnungszeiten:</li>
            <li>Montag: ab 8 Uhr geöffnet</li>
            <li>Dienstag: ab 8 Uhr geöffnet</li>
            <li>Mittwoch:<font className="teilUeberschrift"> Praxis geschlossen</font> - nur Hausbesuche</li>
            <li>Donnerstag: ab 8 Uhr geöffnet</li>
            <li>Freitag: ab 8 Uhr geöffnet</li>
           </ul>
           </p>
          {close}
        </article>

        <article id="team" className={`${this.props.article === 'team' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Unser Team</h2>
         <br></br>      
          <div className="artikelInhalte">     
            <span className="image_wthtbckgrnd left" id="bildGabi"><img src={gabi} alt="" /></span>
           <font className="teilUeberschrift"> Gabriela Marotzke</font>
              <ul>
                <li>» examinierte Podologin</li>
                <li>» sektorale Heilpraktikerin auf dem Gebiet der Podologie</li>
                <li>» Wundassistentin DDG ab Herbst 2019</li>
              </ul>
            </div>
             
     
          <div className="artikelInhalte">            
          <span className="image_wthtbckgrnd right" id="bildCelina"><img src={celina} alt="" /></span>
          <font className="teilUeberschrift">&ensp;Celina Marotzke</font>
              <ul id="celinaUl">
                <li>» examinierte Podologin</li>
                <li>» Wundassistentin DDG ab Herbst 2019</li>
              </ul>
          </div>    

          <div className="artikelInhalte">
          <span className="image_wthtbckgrnd left" id="bildMelanie"><img src={koch} alt="" /></span>
          <font className="teilUeberschrift"> Melanie Koch</font>
              <ul>
                <li>» Praxisassistentin</li>
              </ul>
          </div>    

          {close}
        </article>

        <article id="neuigkeiten" className={`${this.props.article === 'neuigkeiten' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Neuigkeiten</h2>
          <span className="image_wthtbckgrnd main"><img src={neuigkeitenNeu} alt="" /></span>
          <p>
          <ul>
          <PostPage/>
          </ul>
          </p>
          {close}
        </article>

        <article id="anfahrt" className={`${this.props.article === 'anfahrt' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Anfahrt</h2>
          <span className="image_wthtbckgrnd main"><img src={anfahrtNeu} alt="" /></span>
          {/* <span className="image main"><img src={pic07} alt="" /></span> */}
          <p><span className="icon fa-map-signs"></span>&ensp;Adresse: <a href="https://goo.gl/maps/XFexjrTGYJS2" target="_blank" rel="noopener noreferrer">Wiltbergstraße 36 13125 Berlin-Buch</a></p>
          <p>S-Bahn:<br></br>
          Linie S2 Bernau &lt; &gt; Blankenfelde S-Bhf. Buch aussteigen </p>
          <p>BVG: <br></br>
          Bus Nr.: 150, 158, 259
          Haltestelle: S-Bahnhof Buch </p>
{/*           <p>BBG: <br></br>
          Bus Nr.: 893
          Haltestelle: S-Bahnhof Buch </p>
          <p>NEB: <br></br>
          RB 27 bis S-Karow
          Weiter mit der S2
          Richtung Bernau </p> */}
          <p><span className="icon fa-wheelchair"></span><font className="teilUeberschrift">&ensp;Barrierefreie Praxis!</font> <br></br>Parkplätze sind vorhanden </p>
          {close}
        </article>


        <article id="leistung" className={`${this.props.article === 'leistung' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Unsere Leistungen</h2>
          <span className="image_wthtbckgrnd main"><img src={leistungNeu} alt="" id="leistungenPic" /></span>
          <p><span className="icon fa-check-square"></span>&ensp;  <font className="teilUeberschrift">Podologische Komplexbehandlung</font> <br></br> Diabetisches Fußsyndrom mit Heilmittelverordnung alle Kassen</p>

          <p><span className="icon fa-check-square"></span>&ensp; <font className="teilUeberschrift">Behandlung von Haut- und Nagelerkrankungen</font><br></br>
          »&ensp;Mykosetherapie (Haut-Nagelpilztherapie)<br></br>
          »&ensp;Clavientfernung (Hühneraugenentfernung)<br></br>
          »&ensp;Verrucaebehandlung (Warzenbehandlung)<br></br>
          »&ensp;Unguis incarnatus Therapie (Behandlung von eingewachsen Nägeln)<br></br>
          »&ensp;Hyperkeratosen- und Rhagadenbehandlung<br></br>&ensp;&nbsp;&nbsp;(Abtragung von krankhaften Verhornungen)<br></br>
          </p>
          
          <p><span className="icon fa-check-square"></span> &ensp;Druck- und Reibungsschutz</p> 

          <p><span className="icon fa-check-square"></span> &ensp;Individuelle Orthosenanfertigung</p> 

          <p><span className="icon fa-check-square"></span> &ensp;Nagelkorrektur</p> 

          <p><span className="icon fa-check-square"></span> &ensp;Taping</p> 
 
          <p><span className="icon fa-check-square"></span>&ensp; Individuelle Beratung und Anleitung für die Fußgesundheit </p>
    
          {close}
        </article>

        <article id="kontaktBckp" className={`${this.props.article === 'kontaktBckp' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Kontakt</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Nachricht</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Senden" className="special" /></li>
              <li><input type="reset" value="Zurücksetzen" /></li>
            </ul>
          </form>
          {close}
        </article>

        <article id="kontakt" className={`${this.props.article === 'kontakt' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Kontakt</h2>
          <span className="image_wthtbckgrnd main"><img src={anfahrtNeu} alt="" /></span>
          <p><span className="icon fa-phone-square"></span> &ensp;Telefonnummer: <a href="tel:+4930 51 06 77 06"> +49(0)30 51 06 77 06</a></p> 
          <p><span className="icon fa-envelope-square"></span> &ensp;E-Mail: <a href="mailto:praxis@podologie-buch.de"> praxis@podologie-buch.de</a></p>
          <p><span className="icon fa-map-signs"></span> &ensp;Adresse: <a href="https://goo.gl/maps/XFexjrTGYJS2" target="_blank" rel="noopener noreferrer">Wiltbergstraße 36 13125 Berlin-Buch</a></p>
          <h3 className="major">Anfahrt</h3>
          {/* <span className="image main"><img src={pic07} alt="" /></span> */}
          <p><a href="https://goo.gl/maps/XFexjrTGYJS2" target="_blank" rel="noopener noreferrer">Wegbeschreibung anzeigen</a></p>
          <p>S-Bahn:<br></br>
          Linie S2 Bernau &lt; &gt; Blankenfelde S-Bhf. Buch aussteigen </p>
          <p>BVG: <br></br>
          Bus Nr.: 150, 158, 259
          Haltestelle: S-Bahnhof Buch </p>
{/*           <p>BBG: <br></br>
          Bus Nr.: 893
          Haltestelle: S-Bahnhof Buch </p>
          <p>NEB: <br></br>
          RB 27 bis S-Karow
          Weiter mit der S2
          Richtung Bernau </p> */}
          <p><span className="icon fa-wheelchair"></span><font className="teilUeberschrift">&ensp;Barrierefreie Praxis!</font> <br></br>Parkplätze sind vorhanden </p>

          {close}
        </article>

        <article id="agb" className={`${this.props.article === 'agb' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">AGB</h2>
          <p>hier AGB Text</p>

        </article>


        <article id="impressum" className={`${this.props.article === 'impressum' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Impressum</h2>
          <p>Angaben gemäß § 5 TMG<br></br>
          Gabriela Marotzke  <br></br>
          Wiltbergstraße 36<br></br>
          13125 Berlin-Buch<br></br> 
          <br></br>
          Vertreten durch:<br></br> 
          Gabriela Marotzke<br></br>
          <br></br>
          Kontakt: <br></br>
          Telefon: 030-51067706<br></br>
          E-Mail: gmarotzke@podologie-buch.de<br></br>
          <br></br>
          Aufsichtsbehörde: <br></br>
          Landesamt für Gesundheit und Soziales<br></br>
          <br></br>
          Haftungsausschluss: <br></br>

          Haftung für Inhalte
          <br></br>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          <br></br><br></br>
          Haftung für Links
          <br></br>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          <br></br><br></br>
          Urheberrecht
          <br></br>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          <br></br><br></br>
          Datenschutz
          <br></br>
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. 
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. 
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
          <br></br>
          <br></br>
          Google Analytics
          <br></br>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (''Google''). Google Analytics verwendet sog. ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.

          </p>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main

const BlogPost = ({node}) => {
  return (
    
    <li>
      <p>
      <img src={node.image.resize.src} alt="test icon"></img>

      <h3><a href={node.slug} target="_blank">{node.title}</a></h3>
      
      <h5>{node.date}</h5>
      
      <div>{node.subtitle}</div>
      <br></br>
      <a href={node.slug} target="_blank">hier weiterlesen</a>
      </p>
    </li>

  )
}

const PostPage = () => (
  <StaticQuery
    query={graphql`
      query mainPageQuery {
        allContentfulBlog (filter: {
          node_locale: {eq: "en-US"}
        },
        sort:{ fields: [date], order: DESC}
        ) {
          edges {
            node {
              title
              slug
              subtitle
              date(formatString:"DD.MM.YYYY")
              image {
                resize(width:100, height:100) {
                  src
                }
              }
            }
          }
        }
      }  
  `}
    render={data =>(
      <Layout>
      <ul className="blog-post">
        {data.allContentfulBlog.edges.map((edge) => <BlogPost node={edge.node} />)}
      </ul>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        
      </div>
      </Layout>
    )}
    />
)


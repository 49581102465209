import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
             <p className="copyright">  <a href="https://www.facebook.com/Podologie-Zentrum-Berlin-Buch-487770028031456/" target="_blank" rel="noopener noreferrer"> &ensp;Facebook</a> | <a href="javascript:;" onClick={() => {props.onOpenArticle('impressum')}}>Impressum</a> </p>
        
    </footer>
)
{/* Backup Footer mit AGB drinn <p className="copyright">  <a href="https://www.facebook.com/Podologie-Zentrum-Berlin-Buch-487770028031456/" target="_blank" rel="noopener noreferrer"> &ensp;Facebook</a> | <a href="javascript:;" onClick={() => {props.onOpenArticle('agb')}}>AGB</a> | <a href="javascript:;" onClick={() => {props.onOpenArticle('impressum')}}>Impressum</a> </p> */}


Footer.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Footer





